/* ---------------------------------
Card
--------------------------------- */

import React from "react";
import { Box, Heading, Image, Link, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

type OwnProps = {
  image?: string;
  timestamp: string;
  body: string;
  buttonLabel?: string;
  buttonAction?: (e: React.MouseEvent) => void;
  buttonNavUrl?: string;
} & Record<string, unknown>;

const Card: React.FC<OwnProps> = ({
  image,
  timestamp,
  body,
  buttonLabel = "Scopri",
  buttonAction,
  buttonNavUrl,
  ...props
}) => {
  return (
    <Box
      as={"article"}
      w={[280, null, null, 378]} // { sm: "30em", md: "48em", lg: "62em", xl: "80em" }
      bg={"utils.white"}
      {...props}
    >
      {image && (
        <Box>
          {/*
          container in case we need
          to add other elements such as tags

          Skeleton is not used ATM,
          but I'm leaving it just in case

        <Skeleton isLoaded>
        */}
          <Image src={image} alt={body.substr(0, 10)} />
          {/*</Skeleton>*/}
        </Box>
      )}

      <Box p={8} textAlign={"left"}>
        <Heading
          as={"h3"}
          fontSize={"18px"}
          color={"utils.gray.02"}
          fontWeight={"normal"}
        >
          {timestamp}
        </Heading>

        <Text
          fontSize={"24px"}
          mb={8}
          isTruncated
          noOfLines={3}
          lineHeight={"31px"}
        >
          {body}
        </Text>

        <Link
          as={GatsbyLink}
          to={buttonNavUrl}
          display={"block"}
          textAlign={"center"}
          border="2px"
          borderColor="accent.03"
          color="accent.03"
          h={"auto"}
          py={3} // => 'n' * 4 px
          px={4} // => 'n' * 4 px
          textTransform={"uppercase"}
          onClick={buttonAction}
          _hover={{
            textDecoration: "none",
            bg: "hover.02",
          }}
        >
          {buttonLabel}
        </Link>
      </Box>
    </Box>
  );
};

export default Card;
