/* ---------------------------------
/news
--------------------------------- */

import React from "react";
import { graphql, PageProps } from "gatsby";
import { Heading, SimpleGrid } from "@chakra-ui/react";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { makeSlug } from "../../utils";
import Card from "../../components/Card";

const NewsPage: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout customMenu={[]}>
      <Section
        containerProps={{
          maxWidth: "container.lg",
          padding: 0,
        }}
      >
        <Heading py={20} textAlign={"center"}>
          Tutte le news
        </Heading>

        <SimpleGrid
          templateColumns="repeat(auto-fit, minmax(280px, 360px))"
          justifyContent={"center"}
          spacing={8}
          pb={10}
        >
          {data.allFile.edges.map(
            ({
              node: {
                id,
                childMarkdownRemark: { frontmatter, excerpt },
              },
            }) => (
              <Card
                key={id}
                timestamp={frontmatter?.timestamp}
                body={excerpt}
                image={frontmatter?.thumbnail?.childImageSharp?.fixed?.src}
                w={"full"}
                buttonNavUrl={
                  makeSlug(
                    [frontmatter?.timestamp, frontmatter?.title],
                    "news"
                  ) ?? "/news"
                }
              />
            )
          )}
        </SimpleGrid>
      </Section>
    </Layout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "news" }, ext: { eq: ".md" } }
      sort: { fields: birthTime }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              title
              timestamp
              thumbnail {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            excerpt(pruneLength: 70)
          }
        }
      }
    }
  }
`;
